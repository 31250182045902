import React from "react";

import { StaticImage } from "gatsby-plugin-image";

import "@fontsource/libre-baskerville";
import "@fontsource/montserrat";

import { Footer, Navigation, Title } from "./index";

const Body = (): JSX.Element => {
  return (
    <div className="relative">
      <StaticImage
        alt="Eye dropper next to plant."
        loading="eager"
        placeholder="blurred"
        src="../../../../images/cbd-truth-donate.jpg"
        style={{ minHeight: "830px" }}
      />
      <div className="absolute flex flex-col items-center bg-white opacity-90 text-black font-light lg:max-w-xl inset-0 lg:left-auto lg:right-0 lg:top-0 lg:right-0 lg:w-3/5 z-10 p-5 lg:p-10">
        <div className="flex flex-wrap flex-col mt-5 md:mt-0 items-center">
          <StaticImage
            alt="CBD Truth logo."
            loading="eager"
            src="../../../../images/cbd-truth-logo.svg"
            layout="fixed"
            width={78}
            height={92}
          />
          <h1 className="text-2xl text-cbd-truth-dark-blue font-bold mt-5 md:mt-0 md:text-4xl" style={{ fontFamily: "Libre Baskerville" }}>CBD Truth</h1>
          <span className="mt-1 border border-cbd-truth-light-blue w-48 md:w-96" style={{ height: "1px" }} />
          <span className="mt-1 text-lg text-cbd-truth-blue font-medium text-center md:text-2xl">SEPARATE THE FACT FROM THE FICTION ABOUT CBD</span>
        </div>
        <p className="mt-8">CBD Truth produces brochures about CBD for groups to give out to their members or the public. They also give presentations for various groups to help them learn about the potential benefits and risks of CBD use.</p>
        <p className="mt-8 text-3xl font-medium text-center">Please donate to CBD Truth to
        support this important work.</p>
        <div className="relative flex">
          <button className="absolute flex whitespace-nowrap px-8 py-4 bg-cbd-truth-light-blue text-4xl text-cbd-truth-light-blue font-medium rounded outline-none left-0 top-0 ml-2 mt-7 z-10">
            Donate now.
          </button>
          <button className="bg-cbd-truth-blue flex whitespace-nowrap text-4xl text-white px-8 py-4 rounded outline-none focus:ring-4 focus:ring-cbd-truth-light-blue mt-5 font-medium underline outline-none focus:ring-4 focus:ring-cbd-truth-light-blue z-10">
            Donate now.
          </button>
          </div>
      </div>
    </div>
  );
};

const CBDTruthPage = (): JSX.Element => {
  return (
    <div className="flex flex-col min-h-screen" style={{ fontFamily: "Montserrat" }}>
      <Title />
      <Navigation />
      <Body />
      <Footer />
    </div>
  );
};

export default CBDTruthPage;
